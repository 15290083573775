/*

88888888ba     ,ad8888ba,    88888888ba,  8b        d8            
88      "8b   d8"'    `"8b   88      `"8b  Y8,    ,8P             
88      ,8P  d8'        `8b  88        `8b  Y8,  ,8P              
88aaaaaa8P'  88          88  88         88   "8aa8"               
88""""""8b,  88          88  88         88    `88'                
88      `8b  Y8,        ,8P  88         8P     88                 
88      a8P   Y8a.    .a8P   88      .a8P      88  888  888  888  
88888888P"     `"Y8888Y"'    88888888Y"'       88  888  888  888  

*/

.App {
  margin-left: 0px;
}
  

// more body stuff in layout.scss!!
body {
  font-family: $font-main, Arial, sans-serif; 
  font-weight: 400;
  background-color: #fff;

  // font-size: 16px;

  // @include respond(phone) {
  //   font-size: 18px;
  // }
}

.condensed {
  font-family: "Asap Condensed", "Open Sans Condensed", "Arial Narrow", sans-serif !important;
}

.capitalize {
  text-transform: capitalize;
}

html, body {
  text-rendering: optimizeLegibility;
}

a {
  color: inherit;
}
.facebook {
  color: #3b5998;
}

h1 {
  font-size: 1.8rem;
  font-weight: 600;
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

h3 {          
  font-size: 1.1rem;  
}

// h4 {          
//   font-size: 0.8rem;  
//   color: #888;
// }

button.image-as-button {
  padding: 0;
  border: none;
  background-color: transparent;
}

.hide-if-last {
  &:last-child {
    display:none;
  }
}


.inline-nowrap {
  display: inline;
  white-space: nowrap;
}


button {
  border: 1px solid #ccc;
  &.cancel {
    background-color: #eee;
    color: #777;
  }
}

// BOOTSTRAP OVERRIDES

nav .btn.btn-secondary:hover {
  background-color: transparent;   // to override bootstrap bg color on hover (help menu)
}