
/*

##     ##    ###    ##        #######   ######  
##     ##   ## ##   ##       ##     ## ##    ## 
##     ##  ##   ##  ##       ##     ## ##       
######### ##     ## ##       ##     ##  ######  
##     ## ######### ##       ##     ##       ## 
##     ## ##     ## ##       ##     ## ##    ## 
##     ## ##     ## ########  #######   ######  

*/

.halos-container {
  overflow-y: visible;  // doesn't work..?! high halos still cropped
}

.note-click-area {
  position: absolute;
  background: none;
  border: none;
  opacity: 0.01;       // If it's zero then element is not interactable on ios
  // background-image: linear-gradient(to right, #999, #ccc);
  // opacity: 0.8;       // If it's zero then element is not interactable on ios
  transition: opacity 0.2s;
  z-index: 100;

  @media (hover: hover) {
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  &:focus {
    outline: 0;
  }

  &.disabled:hover {
    opacity: 0;
    cursor: default;
  }
}

$halo-color-sounding: rgb(49, 129, 221); // hsl(180, 94%, 58%);
$halo-color-default: rgb(109, 109, 109); // hsl(180, 94%, 58%);
$halo-color-raised: rgb(34, 160, 30); // hsl(180, 94%, 58%);
$halo-color-arpeggio: rgb(166, 59, 180); // hsl(180, 94%, 58%);
$halo-color-flip: rgb(38, 182, 207); // hsl(180, 94%, 58%);

@mixin pulse($name, $color, $size: 15px ) {
  box-shadow: 0 0 0 0 darken(rgba($color, 0.8), 20%);
  animation: pulse-#{$name} 2.5s 1;
  @keyframes pulse-#{$name} {
    0%   { box-shadow: 0 0 0 0 rgba($color, 0.8); }
    70%  { box-shadow: 0 0 0 $size rgba($color, 0); }
    100% { box-shadow: 0 0 0 0 rgba($color, 0); }
  }
}

// pulse-1 is essentially a duplicate of above, needed else multiple consecutive actions
// won't display the halo animation. Cf .note-halo-1
@mixin pulse-1($name, $color, $size: 15px ) {
  box-shadow: 0 0 0 0 darken(rgba($color, 0.8), 20%);
  animation: pulse-1-#{$name} 2.5s 1;
  @keyframes pulse-1-#{$name} {
    0%   { box-shadow: 0 0 0 0 rgba($color, 0.8); }
    70%  { box-shadow: 0 0 0 $size rgba($color, 0); }
    100% { box-shadow: 0 0 0 0 rgba($color, 0); }
  }
}


// @mixin pulse-quick($name, $color, $size: 9px ) {
//   box-shadow: 0 0 0 0 darken(rgba($color, 0.3), 20%);
//   animation: pulse-#{$name} 2.5s 1;
// }

@keyframes pulse-quick {
  0% {   box-shadow: 0 0 0 0 rgba($halo-color-sounding, 0.2);  }
  4% {   box-shadow: 0 0 0 12px rgba($halo-color-sounding, .5);  }
  12% {  box-shadow: 0 0 0 10px rgba($halo-color-sounding, 0.2);  }
  100% { box-shadow: 0 0 0 10px rgba($halo-color-sounding, 0);  }
}


.note-hover {
  position: absolute;
  z-index: -1;
  border: 1px solid hsla(0, 0, 50%, 0.4);
}


// Two essentially identical classes for note halos so that I can flip-flop them,
// which ensures that the halo animation always happens, even if an action is
// immediately after the previous one.
.note-halo-0,.note-halo-1 {
  position: absolute;
  // z-index: -1;   // 000337: removed this cos I set ul.scale-list to background: #fff else tour spotlight looks crap,
                    // side-effect was that halos disappeared. Tbh I can't remember why I had it at -1, possibly to put
                    // behind the notes? But it seems fine without it.
  width: $NOTE_HALO_WIDTH * $CSS_ZOOM;
  height: $NOTE_HALO_WIDTH * $CSS_ZOOM;
  border-radius: 30px;
}

.note-halo-0 {
  &.sounding { animation: pulse-quick 2s 1 }
  &.arpeggio, &.chord { @include pulse(arpeggio, $halo-color-arpeggio) }
  &.raised { @include pulse(raised, $halo-color-raised, 20px) }
  &.flip { @include pulse(flip, $halo-color-flip) }
  &.default { @include pulse(default, $halo-color-default, 12px) }

  &.disabled-note {opacity: 0.4;}
}

.note-halo-1 {
  &.sounding { animation: pulse-quick 2s 1 }
  &.arpeggio, &.chord { @include pulse-1(arpeggio, $halo-color-arpeggio) }
  &.raised { @include pulse-1(raised, $halo-color-raised, 20px) }
  &.flip { @include pulse-1(flip, $halo-color-flip) }
  &.default { @include pulse-1(default, $halo-color-default, 12px) }

  &.disabled-note {opacity: 0.4;}
}



// .vibrating-ring {
//   position: absolute;
//   z-index: -1;
//   border-radius: 30px;
//   opacity: 0;

//   &.a {
//     border: 1px solid hsla(170, 60%, 50%, 0.5);
//     // animation: vibrate .1s 10 alternate, fade-in-out 1s 1;
//   }
//   &.b {
//     border: 1px solid hsla(199, 55%, 56%, 0.5);
//     // animation: vibrate .12s 8 alternate, fade-in-out 1s 1;
//   }
//   &.grow {
//     opacity: 0;
//     border: 2px solid hsla(199, 55%, 56%, 0.5);
//     animation: grow-shrink 0.4s 1;
//   }
// }
// @keyframes vibrate {
//   0% { transform: translate(.5px, .5px); }
//   33% { transform: translate(-.5px, .5px); }
//   66% { transform: translate(-.5px, -.5px); }
//   100% { transform: translate(.5px, -.5px); }
// }
// @keyframes fade-in-out {
//   0% {     opacity: .5;   }
//   5% {     opacity: 1;   }
//   75% {     opacity: 1;   }
//   100% {     opacity: 0;   }
// }
// @keyframes grow-shrink {
//   0% { transform: scale(0.4); opacity: 1; }
//   10% { transform: scale(1.3); opacity: 1; }
//   20% { transform: scale(1); opacity: 0.2; }
//   80% { transform: scale(1); opacity: 0.2; }
//   90% { transform: scale(1); opacity: 1; }
//   100% { transform: scale(0.2); opacity: 0.5; }
// }

