/*
                                                                   
  ,ad8888ba,    ,ad8888ba,    88           ,ad8888ba,    88888888ba    ad88888ba   
 d8"'    `"8b  d8"'    `"8b   88          d8"'    `"8b   88      "8b  d8"     "8b  
d8'           d8'        `8b  88         d8'        `8b  88      ,8P  Y8,          
88            88          88  88         88          88  88aaaaaa8P'  `Y8aaaaa,    
88            88          88  88         88          88  88""""88'      `"""""8b,  
Y8,           Y8,        ,8P  88         Y8,        ,8P  88    `8b            `8b  
 Y8a.    .a8P  Y8a.    .a8P   88          Y8a.    .a8P   88     `8b   Y8a     a8P  
  `"Y8888Y"'    `"Y8888Y"'    88888888888  `"Y8888Y"'    88      `8b   "Y88888P"   

*/

$color-primary-text: #212121;
$color-text-subdued: #686767;  // using for scale message
$color-link: rgb(13, 99, 105);
$color-info: rgb(25, 76, 110);
$color-warning: rgb(112, 97, 13);
$color-error: rgb(73, 18, 18);

$color-dropdown-item-hover: rgb(221, 221, 221);
$color-action-item: #808080;
$color-action-hover: lighten($color-action-item, 40%);

$color-play: rgb(96, 167, 96);
$color-stop: hsl(00, 90%, 24%);  


// !!! MORE COLOURS IN varsColorsLightBar.scss...


//??? prob should use this (or something like it) more univerally on all buttons
// and button-like things
// Think about does it mean 'switched on' or does it mean 'in the act of being pressed' (i.e. brief)?
@mixin pressed {
  box-shadow: 0px 0px 3px rgba(0,0,0,0.2) inset;
}

@mixin dark-overlay {
  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
  }
}




/*

########  #### ##     ## ######## ##    ##  ######  ####  #######  ##    ##  ######  
##     ##  ##  ###   ### ##       ###   ## ##    ##  ##  ##     ## ###   ## ##    ## 
##     ##  ##  #### #### ##       ####  ## ##        ##  ##     ## ####  ## ##       
##     ##  ##  ## ### ## ######   ## ## ##  ######   ##  ##     ## ## ## ##  ######  
##     ##  ##  ##     ## ##       ##  ####       ##  ##  ##     ## ##  ####       ## 
##     ##  ##  ##     ## ##       ##   ### ##    ##  ##  ##     ## ##   ### ##    ## 
########  #### ##     ## ######## ##    ##  ######  ####  #######  ##    ##  ######  

*/



$navbar-height: 38px;
$navbar-search-height: 70px;
$navbar-sorting-height: 40px;

$header-height: $navbar-height + $navbar-search-height + $navbar-sorting-height;

$grid-margin: 1.3em;
$grid-bullet-width: 29px;
$grid-bullet-margin: 2.5px;

$main-margin-width: 10px;     // must correspond w constant MAIN_MARGIN_WIDTH_PX !!!
$settings-sidebar-width: 430px;




$phone-navbar-height: 62px;           // i.e. the bottom nav bar
$phone-navbar-search-height: 90px;    // mainly for offsetting stuff like the loading spinner atm
// $one-line-search-bar-height: 50px;    // e.g. on tablet, where brand & search are on one line

$phone-grid-margin: 5vmin;
$phone-grid-bullet-width: 8.6vmin;
$phone-grid-bullet-margin: 0.6vmin;

