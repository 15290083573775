
/*

 ######  ######## ######## ######## #### ##    ##  ######    ######  
##    ## ##          ##       ##     ##  ###   ## ##    ##  ##    ## 
##       ##          ##       ##     ##  ####  ## ##        ##       
 ######  ######      ##       ##     ##  ## ## ## ##   ####  ######  
      ## ##          ##       ##     ##  ##  #### ##    ##        ## 
##    ## ##          ##       ##     ##  ##   ### ##    ##  ##    ## 
 ######  ########    ##       ##    #### ##    ##  ######    ######  

 */



.option-name {
  display: inline-block;
  margin-right: 0.6em;
  color: $color-action-item;
  font-weight: initial;
}

#settings-window {
  color: $color-sidebar-text;
  background-color: $color-sidebar;
  font-size: 0.95rem;

  @include respond(tab-land) {
    font-size: 1rem;
  }

  & .btw {
    color: #999;
  }
}

.sidebar-footer {
  font-size: 0.8em;
  color: $color-sidebar-text-subdued;
  padding-bottom: $phone-navbar-height + $phone-navbar-height;

  @include respond(tab-land) {
    font-size: 0.75em;
  }
}

  
.sidebar-section-button {
  display: block;
  position: relative;
  width: 100%;
  text-align: left;
  background-color: $color-sidebar;
  border: none;
  box-sizing: border-box;
  border-top: 1px solid $color-sidebar-lines;
  padding: 0.7em 1em;
  color: inherit;
  z-index: 1;

  &.active {
    // position: sticky;
    // top: 0;
    // border-bottom: 1px solid scale-color($color-sidebar-lines, $lightness: -20%);
    // background-color: $color-sidebar-active;
    font-weight: 600;
  }
  @media (hover: hover) {
    &:hover {
      background-color: scale-color($color-sidebar-active, $lightness: -12%);
      color: unset;
      transition: background-color 0.1s;
    }
  }
  &:active {
    background-color: $color-sidebar-active;
    transition: background-color 0.3s;
  }

  &>*:first-child {
    margin-right: 1em;  // space after icon
  }

  &>.appropriate-caret {
    position: absolute;
    top: 0.7em;
    right: 1em;
  }
}


.sidebar-pane { 
  color: $color-sidebar-text;
  // font-size: 0.95rem;
  padding: 1em 1em 2em 1em;
  border-top: 1px solid $color-sidebar-lines;

  &:first-of-type {
    border: none;
  }

  // &.active {
  //   background-color: $color-sidebar-active;
  // }

  // &>* {
  //   margin-bottom: 2em;
  // }

  & h2 {
    font-size: 1.6em;
    font-weight: 600;
  }

  & h3 {
    &:not(:first-child) {
      margin-top: 1em;
    }
    font-size: 1.2em;
    font-weight: 600;
    color: inherit;
  }

  & h4 {
    margin-top: 1em;
    font-size: 1em;
    font-weight: 600;
    color: inherit;
  }

  // & p {

  // }

  & a {
    color: $color-sidebar-link;
  }

  & ul {
    padding-inline-start: 25px;
  }

  & li {
    margin: 0.4em 0;
  }

  & .sb-setting-box {
    margin-top: 0.5em;
    
    &>.option-name {
      vertical-align: middle;
      min-width: 10em;
      color: inherit;
    }

  }
  & .option-explanation {
    font-size: 0.92em;
    color: $color-sidebar-text-subdued;
    margin-top: 0.3em;
    padding-left: 1em;
  }

  & div.rbt {
    display: inline-block;
    width: 45%;
  }

  & .form-control-sm {
    height: 1.7em;
    border-radius: 0;
    // padding-top: 0;
    // padding-bottom: 0;
  }
}

.sidebar-close-button {
  position: absolute;
  right: 10px;
  top: -1px;
  z-index: 2001;
  padding: 0.2em;
  text-align: center; 
  font-size: 1.4em;
  color: inherit;
  cursor: pointer;
  transition: color 0.1s;
  
  &:hover {
    border-color: transparent !important;
    color: #fff;
  }
}


.sidebar-control {
  display: inline-block;
  border: none;
  font-size: inherit;
  font-weight: inherit;
  padding: 0 0.2em;
  margin-left: 0;
  border-radius: 0;
  // line-height: 1.5;

  background-color: $color-sidebar-button;
  color: #fff;

  &:hover {
    background-color: scale-color($color-sidebar-button, $lightness: 15%);
  }
}


// .settings-typeahead {
  
//   & .dropdown-header {
//     padding-left: 1em;
//   }

//   & a.dropdown-item {
//     color: $color-primary-text;
//     font-size: 0.9em;
//     padding-left: 1em;

//     &:hover {
//       color: $color-primary-text;
//       background-color: $color-dropdown-item-hover;
//     }
//   }
// }


.slide-in-from-right-enter {
  transform: translateX( 100% );
}
.slide-in-from-right-enter-active {
  transform: translateX( 0px );
  transition: all 400ms;
}
.slide-in-from-right-exit {
  transform: translateX(0);
}
.slide-in-from-right-exit-active {
  transform: translateX( 100% );
  transition: all 160ms;
}
  

.credits {
  & p:not(:last-child){
    margin-bottom: 0rem;
  }
}