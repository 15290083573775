
/*

88b           d88  88888888888  88888888ba,    88         db              ,ad8888ba,    
888b         d888  88           88      `"8b   88        d88b            d8"'    `"8b   
88`8b       d8'88  88           88        `8b  88       d8'`8b          d8'        `8b  
88 `8b     d8' 88  88aaaaa      88         88  88      d8'  `8b         88          88  
88  `8b   d8'  88  88"""""      88         88  88     d8YaaaaY8b        88          88  
88   `8b d8'   88  88           88         8P  88    d8""""""""8b       Y8,    "88,,8P  
88    `888'    88  88           88      .a8P   88   d8'        `8b       Y8a.    Y88P   
88     `8'     88  88888888888  88888888Y"'    88  d8'          `8b       `"Y8888Y"Y8a  

*/

// ???266 Should look at all these..

// phone now includes landscape - ok?
// but also a danger it will include tab-port - is that what I want?
// see commented out stuff below - perhaps sometimes naming by version name is a good idea, then 
// just configure here which device-sizes get that version.

// NB. THESE SHOULD CORRESPOND WITH JAVASCRIPT MEDIA QUERIES in miscUtils.scss!!! (such as isTabletOrPhone)
@mixin respond($device) {
    @if $device == phone-portrait {
      @media only screen and (max-width: 575px) { @content };
    }
    @if $device == phone-landscape {
      @media only screen and (min-width: 576px) and (max-width: 767px) and (max-height: 575px) { @content };
    }
    @if $device == phone {
      @media only screen and (max-width: 767px) { @content };
    }
    @if $device == phone-onscreen-keyboard {
      @media only screen and (max-height: 374px) { @content };
      // ???471: Problem is to have a number which is
      //   - smaller than screen height in landscape (else bottom menu doesn't appear *at all* in landscape)
      //   - but bigger than what remains of screen height in portrait when keyboard is showing
      // 374 works on my pixel 2 (whose screen width is 375) - but might not satisfy others..?
    }
    @if $device == phone-landscape-keyboard {       // doesn't work on iphone (000441)
      @media only screen and (orientation: landscape) and (max-height: 300px) { @content };
    }
    @if $device == tab-port {
      @media only screen and (max-width: 900px) { @content };
    }
    @if $device == tab-land {
      @media only screen and (max-width: 1200px) { @content };
    }
    @if $device == not-phone {
      @media only screen and (min-width: 768px) { @content };
    }
  }
  
// @mixin respond($device) {
//   @if $device == phone {
//     // @media only screen and (max-width: 575px) { @content };
//     @media only screen and (max-width: 767px) and (max-height: 767px) { @content };
//   }
//   // @if $device == tight-version {
//   //   @media only screen and (max-width: 750px) and (max-height: 750px) { @content };
//   // }
//   @if $device == not-phone {
//     // @media only screen and (max-width: 575px) { @content };
//     @media only screen and (min-width: 768px), only screen and (min-height: 768px) { @content };
//   }
//   // @if $device == tab-port {
//   //   @media only screen and (max-width: 900px) { @content };
//   // }
//   // @if $device == tab-land {
//   //   @media only screen and (max-width: 1200px) { @content };
//   // }
// }


  @include respond(phone) {
    button:focus { outline: none; }
  }

  


//////////////////////////////////////////////
//
//      ADJUSTMENTS FOR BIG SCREENS
//
//////////////////////////////////////////////

@media only screen and (min-width: 768px) {
    // .scale-title {
    //   margin: 0 0.3rem 0 0.8rem;
    // }
  
    /* .notation-box {
      Idea was to have wider 'margin' on larger screens - but hard to deal with this in sizing the staff to fit, so removed for now.. 00062
      grid-template-columns: 20px 1fr 20px;
    } */
  
    .spacer-right {
      display: none;
    }
  }
  