
/*


88888888888  ,ad8888ba,    888b      88  888888888888  ad88888ba   
88          d8"'    `"8b   8888b     88       88      d8"     "8b  
88         d8'        `8b  88 `8b    88       88      Y8,          
88aaaaa    88          88  88  `8b   88       88      `Y8aaaaa,    
88"""""    88          88  88   `8b  88       88        `"""""8b,  
88         Y8,        ,8P  88    `8b 88       88              `8b  
88          Y8a.    .a8P   88     `8888       88      Y8a     a8P  
88           `"Y8888Y"'    88      `888       88       "Y88888P"   
                                                                   
                                                                   
*/


// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Cabin+Condensed:wght@400;500;600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Asap:wght@400;500&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Asap+Condensed:wght@400&display=swap');


// @font-face {
//   font-family: pori-text-std;
//   src: url(../assets/PoriTextStd-2019-04-10.otf);
// }
    
@font-face {
  font-family: bravura-text;
  src: url(../assets/BravuraTextSubset.woff);
}

@font-face {
  font-family: fiati;
  src: url(../assets/fiati-regular.woff);
  font-display: block;
}

$font-main: 'Open Sans';
// $font-condensed: 'Cabin Condensed';

// @mixin font-header {    // nav and scale titles
// font-family: $font-main, sans-serif;
// font-weight: 600;
// }

  