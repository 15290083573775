/*

88888888ba   88888888ba     ,ad8888ba,    88b           d88  88888888ba  888888888888  ad88888ba   
88      "8b  88      "8b   d8"'    `"8b   888b         d888  88      "8b      88      d8"     "8b  
88      ,8P  88      ,8P  d8'        `8b  88`8b       d8'88  88      ,8P      88      Y8,          
88aaaaaa8P'  88aaaaaa8P'  88          88  88 `8b     d8' 88  88aaaaaa8P'      88      `Y8aaaaa,    
88""""""'    88""""88'    88          88  88  `8b   d8'  88  88""""""'        88        `"""""8b,  
88           88    `8b    Y8,        ,8P  88   `8b d8'   88  88               88              `8b  
88           88     `8b    Y8a.    .a8P   88    `888'    88  88               88      Y8a     a8P  
88           88      `8b    `"Y8888Y"'    88     `8'     88  88               88       "Y88888P"   

*/

.popover {
  max-width: 100%;
}



// .popover.prompt {  //.??? obsolete i think
//   background: $color-prompt;
//   span.arrow:after {
//     border-bottom-color: $color-prompt !important;
//   }
// }



// .prompt-appear {
//   transform: translateY(10vh);
// }
// .prompt-appear-active,
// .prompt-appear-done {
//   transform: translateY(0);
//   transition: all .9s;
// }


.share-popover {
  width: 400px;
  font-size: 1.1em;
  box-shadow: 0px 3px 10px rgba(0,0,0,0.3);
}

.share-title {
  font-weight: 500;
}

.share-link {
  width: 100%;
  margin: 0.5em 0;
  font-size: 0.92em;
}

.btw {
  font-size: 0.9em;
  color: #777;
}


.scales-toast {
  position: fixed;
  top: $header-height + 20px;
  right: 40px;
  z-index: 99;
  color: $color-primary-text;

  &.copied {
    background-color: #d6fcbd;
  }

  @include respond(phone) {
    top: 15px;
    right: 15px;
  }

}

.wip-container {
  color: rgb(36, 35, 35);
  font-size: 0.94rem;
  margin: 2em 0 0.5em 0;
  // padding: 0px;
}

$col-yellow: rgb(212, 212, 41);
$col-black: #333;

.stripy-border {
  padding: 4px;
  background-image: repeating-linear-gradient(45deg, $col-black, $col-black 10px, $col-yellow 10px, $col-yellow 20px);
}

.stripy-border-inside {
  // box-sizing: content-box;
  margin: 0px;
  background-color: rgb(153, 153, 113);
  font-weight: 600;
}

.wip-content {
  padding: 10px;
}

.cookie-consent-box {
  @include respond(tab-land) {
    position: fixed;
    bottom: $phone-navbar-height !important;
  }
}

.error-fallback {
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

/*

88888888888  888888888888  ,ad8888ba,                  
88                88      d8"'    `"8b                 
88                88     d8'                           
88aaaaa           88     88                            
88"""""           88     88                            
88                88     Y8,                           
88                88      Y8a.    .a8P  888  888  888  
88888888888       88       `"Y8888Y"'   888  888  888  
                                                       
*/



.icon-main {
  fill: #444;
}

.icon-menu-item {   // ???obsolete?
  margin-right: 0.25em;
  fill: #aaa;
}

.loading-spinner {
  animation: icon-spin 2s infinite linear;
  // opacity: 0.5;
  // color: #b5b5b5;
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.react-icon-adjust {
  vertical-align: -9%;
}

.gr-undo-flip-icon {
  transform: rotate(270deg) scaleY(-1);
  margin-right: 0.14em;
}

.loading-list {
  // color: #888;
  opacity: 0.4;
  font-weight: 600;
  padding-top: 18px;

  @include respond(tab-land) {
    padding-top: $phone-navbar-search-height + 18px;
  }
}


@keyframes load-bg {
  15% {  background-color: hsl(0,0,92%); }
  50% {  background-color: hsl(0,0,85%); }
  70% {  background-color: hsl(0,0,92%); }
}


.loading-scale {
  position: absolute;
  width: 100%;
  padding: 5px;
  color: #aaa;
  overflow: hidden;
  background-color: hsl(0,0,92%);
  animation: load-bg 3s infinite;
  // animation: load-bg 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}


.link-like-button {
  padding: 0;
  line-height: 1;
  border: none;
  background: none;
  // font-weight: 600;
  color: $color-link;
}

#settings-window .link-like-button {
  color: $color-sidebar-link;
}


// .button-keysgrid-toggle {
//   position: fixed;
//   top: 0px;
//   z-index: 1999;
//   visibility: hidden;

//   @include respond(phone) {
//     visibility: visible;
//   }
// }


.search-feedback-strip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0 2px $main-margin-width;
  font-size: 0.9rem;

  @include respond(tab-land) {
    &.avoid-phone-search-bar {
      position: fixed;
      // top: $one-line-search-bar-height;
      top: $phone-navbar-search-height;
      z-index: 1030;
      width: 100%;
    }
  }
  
  @include respond(phone) {
    font-size: 1rem;
    min-height: 2.4rem;
    &.avoid-phone-search-bar {  // ???whatyoucando can delete
      top: $phone-navbar-search-height;
    }
  }

  &.info {
    color: scale-color($color-info, $lightness: 0%);
    background-color: scale-color($color-info, $lightness: 85%);
    border: 1px solid scale-color($color-info, $lightness: 74%);
    border-style: solid none;
  }
  &.warning {
    color: scale-color($color-warning, $lightness: 0%);
    background-color: scale-color($color-warning, $lightness: 80%);
    border: 1px solid scale-color($color-warning, $lightness: 58%);
    border-style: solid none;
  }
  &.error {
    color: scale-color($color-error, $lightness: 0%);
    background-color: scale-color($color-error, $lightness: 80%);
    border: 1px solid scale-color($color-error, $lightness: 68%);
    border-style: solid none;
  }
  & a {
    color: $color-link;
    font-weight: 600;
  }
}

// close button for search feedback strip
// ??? make more universal - i.e. all close buttons to share base class
.close-button {
  border: none;
  background-color: transparent;
  color: inherit;

  @include respond(phone) {
    font-size: 1.5rem;
    line-height: 1rem;
  }
}



.rc-tooltip-inner {
  padding: 4px;
  min-height: 0px;
  border-radius: 3px;
  line-height: 1;
}

$color-secondary: rgb(248, 245, 207);

.feedback-form-container {

  & label {
    width: 100%;
    font-size: 0.85em;
    color: inherit;
  }

  & input, & textarea {
    font-size: 1rem;
    border: 1px solid #ccc;
    width: 100%;
    color: #444;
    background-color: rgb(236, 236, 236);
  }

  & textarea {
    height: 9rem;
  }

  & button {
    margin-top: 1em;
    margin-right: 0.5em;

    &[type=submit] {
      font-size: 1.1em;
      padding: 0.2em 0.6em;
      // color: scale-color($color: $color-secondary, $lightness: -75%);
      // border: 1px solid scale-color($color: $color-secondary, $lightness: -50%);
      background-color: scale-color($color: $color-navbar, $lightness: -50%);  
    }
  }
}

.fancy-text {
  background-image: linear-gradient(135deg, hsl(0, 100%, 56%) 10%, hsl(60, 100%, 66%) 40%, hsl(120, 80%, 52%) 65%, hsl(240, 100%, 70%) 80%);
  background-clip: text;
  color: transparent;
}