
/*

   ###     ######  ######## ####  #######  ##    ##  ######  
  ## ##   ##    ##    ##     ##  ##     ## ###   ## ##    ## 
 ##   ##  ##          ##     ##  ##     ## ####  ## ##       
##     ## ##          ##     ##  ##     ## ## ## ##  ######  
######### ##          ##     ##  ##     ## ##  ####       ## 
##     ## ##    ##    ##     ##  ##     ## ##   ### ##    ## 
##     ##  ######     ##    ####  #######  ##    ##  ######  

*/


$color-link: scale-color($color-navbar, $lightness: -65%);


.scale-footer {
  margin: 0.2em $main-margin-width 0px $main-margin-width;
}

.actions-bar {
  width: fit-content;   // so that the feature tour doesn't highlight the entire screen width
  // border: 1px solid red;
  font-size: 0.77em;
  font-weight: 600;
  // margin-top: 0.2em;
  color: $color-action-item;
  line-height: 2.2;    // in case bar wraps
  
  @include respond(phone) {
    font-size: 0.85em;
  }

  &>* {
    margin-right: 1em;
  }

}

.actions-bar-text {
  display: inline-block;
  font-weight: 400;
  
  &:first-child {
    margin-right: 0.6em;
  }
}

.sentence-case {
  text-transform: lowercase;
  &::first-letter{
    text-transform: capitalize;
  }
}

// ??? seems to be obsolete now that minor subtype is a dropdown
// .options-btn-group {   // put this in for navbar-sorting
//   color: $color-action-item;
// }


.text-button, .action-dropdown {
  display: inline-block;
  background: none;
  border: none;
  color: inherit;
  font-weight: inherit;
  padding: 0 0.2em;
  border-radius: 0;
  transition: background-color 0.2s;
  line-height: 1.5;

  &.active {
    background-color: $color-action-item;
    color: #fff;
  }

  &:disabled {
    opacity: 0.5; 
    color: inherit;
  }

  &:not(.active):hover:enabled,
  &:not(.active):focus:enabled {
    background-color: $color-action-hover;
    color: $color-action-item; // ??? doesn't work
  }
}

.action-box {
  display: inline;

}

.action-dropdown {
  border: none;
  background-color: #fff;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  vertical-align: inherit;
}



.copy-link {
  font-size: 0.9em;
  font-weight: 600;
  color: $color-link;

  &:not(.active):hover:enabled,
  &:not(.active):focus:enabled {
    color: scale-color($color-link, $lightness: -25%);
    background-color: scale-color($color-link, $lightness: 75%);

    cursor: pointer;
  }
}


.play-button {
  color: $color-play;
  border: none;
  // background-color: hsla(0,0,100,1);   - Removed so doesn't look weird on WhatYouCanDo
  //                                        ..but not sure what it was supposed to be doing..?!
  transition: all 0.2s;
  
  &:not(.active):hover:enabled,
  &:not(.active):focus:enabled {
  // &:hover:enabled,
  // &:focus:enabled {
    color: darken($color-play, 10%);
    background-color: lighten($color-play, 40%);
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    color: inherit;
  }

  // &:hover:enabled {  // ??? need this '[enamabled?'
  //   cursor: pointer;
  //   opacity: 1;
  // }

  &.stop {
    color: $color-stop; 
    // opacity: 0.5;
    // background-color: white;
    // border: 1px solid #ccc;

    &:not(.active):hover:enabled,
    &:not(.active):focus:enabled {
    //   &:hover,
    // &:focus {
      color: darken($color-stop, 10%);
      background-color: lighten($color-stop, 70%);
    }
  
  }
}

.scale-menu {
  padding: 0 4px;
  // margin-left: 50px;
  font-size: 1.1em;
  color: $color-action-item;
  background-color: transparent;
  border: none;
  border-radius: 80px;
  box-shadow: none; 
}


.scale-menu:hover {
  color: darken($color-action-item, 20%);
  background-color: $color-action-hover;
}



// following is supposed to apply to actions and to sidebar settings
// ??? should find a more generic place for it to live
// and for some reason they don't appear identical? ???297
@include respond(not-phone) {
  h6.dropdown-header {
    font-size: 0.75em;
  }
  button.dd-item, div.dd-item {   // div.dd-item = menu item in react-menu-list
    font-size: 0.9em;
    // padding: 0.24em 1em;   // switched off: the 1em meant that dropdown items were mis-aligned with dropdown header-items
  }
}

