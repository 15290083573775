
/*

######## #### ##    ##  ######   ######## ########  #### ##    ##  ######    ######  
##        ##  ###   ## ##    ##  ##       ##     ##  ##  ###   ## ##    ##  ##    ## 
##        ##  ####  ## ##        ##       ##     ##  ##  ####  ## ##        ##       
######    ##  ## ## ## ##   #### ######   ########   ##  ## ## ## ##   ####  ######  
##        ##  ##  #### ##    ##  ##       ##   ##    ##  ##  #### ##    ##        ## 
##        ##  ##   ### ##    ##  ##       ##    ##   ##  ##   ### ##    ##  ##    ## 
##       #### ##    ##  ######   ######## ##     ## #### ##    ##  ######    ######  

*/

.note-names-and-fingerings {
  grid-column: 2;
}

.fingerings-strip {
  position: relative;
  grid-column: 2;
  margin: 0;
  height: 1em;
  // line-height: 1em; // 2em; // for message
  color: transparent;
  transition: color 2s;
  transition-delay: 2s;

  &:hover,&.show {
    color: transparentize($color-action-item, 0.2);
    transition-delay: 0s;
  }
}


.fingering-control {
  position: absolute;
  top: 0;
  left: 0;
  width: $NOTE_CLICK_AREA_WIDTH * 0.9 * $CSS_ZOOM;
  height: 1em;
  // vertical-align: 10%;
  padding: 0 0;
  font-size: 1em;
  border: none;
  border-radius: 9px;
  opacity: 0.5;
  color: inherit;
  background: none;
  transition: opacity 1s;
  // background-color: rgba(0,0,0,0.2);
  
  &:hover {
    opacity: 1;
    transition: opacity 0.4s;
    color: $color-action-item;
  }
  
  &:focus {
    outline: none;
  }
}
  
.fingering-container {
  position: absolute;
  font-size: 0.7em;
  margin: 0;
  text-align: center;
  top: 0;
}

button.fingering {
  // position: relative;
  min-width:  $NOTE_CLICK_AREA_WIDTH * 0.9 * $CSS_ZOOM;
  font-family: Arial, $font-main, sans-serif;
  color: #777;
  opacity: 0.01;
  border: none;
  padding: 0 0;
  border-radius: 9px;
  background: none;
  transition: opacity 0.5s 0.3s;
  // background-color: hsla(0,0,0,0.2);

  &.show {
    opacity: 1;
    transition: opacity 0.2s 0s;
  }

  @media (hover: hover) {
    &:hover:not(.show) {
      cursor: pointer;
      // background-color: rgb(217, 217, 250);
      opacity: 0.4;
      transition: opacity 0.2s 0s;
    }
  }

  // &:not(:active):not(.show) {
  //   transition: opacity 3s ease-in;  // slow fade
  // }

  &:active {
    color: #000;
    background-color: #ddd;
  }

  &:focus {
    outline: none;
  }
}


$flute-fingering-height: 112px;
$flute-fingering-width: 36px;
$piccolo-fingering-height: 108px;
$piccolo-fingering-width: 36px;
$saxophone-fingering-height: 128px;
$saxophone-fingering-width: 36px;
$clarinet-fingering-height: 148px;
$clarinet-fingering-width: 32px;
$recorder-fingering-height: 106px;
$recorder-fingering-width: 37px;

.fingerings-strip.fingerings.woodwind {

  height: 160px;
  margin-top: 3px;

  & button.fingering-control {
    height: 100%;
  }

  & button.fingering {
    font-family: fiati;
  }

  & .alternate-fingering-caret {
    position: absolute;
    font-size: 1.2rem;
    top: 0;
    right: 0;
  }
  
  &.flute {
    max-height: $flute-fingering-height * 1.08;
    & button.fingering {
      font-size: $flute-fingering-height;
      height: $flute-fingering-height * 1.08;
      width: $flute-fingering-width;
      & .fingering-diagram {
        top: 32px;
        left: 1px;
      }
    }
  }
  &.piccolo {
    max-height: $piccolo-fingering-height * 1;
    & button.fingering {
      font-size: $piccolo-fingering-height;
      height: $piccolo-fingering-height * 1;
      width: $piccolo-fingering-width;
      & .fingering-diagram {
        top: 32px;
        left: 3px;
      }
    }
  }
  &.clarinet {
    max-height: $clarinet-fingering-height * 1.03;
    & button.fingering {
      font-size: $clarinet-fingering-height;
      height: $clarinet-fingering-height * 1.03;
      width: $clarinet-fingering-width;
      & .fingering-diagram {
        top: 40px;
        left: 6px;
      }
    }
  }
  &.saxophone {
    max-height: $saxophone-fingering-height * 1.06;
    & button.fingering {
      font-size: $saxophone-fingering-height;
      height: $saxophone-fingering-height * 1.06;
      width: $saxophone-fingering-width;
      & .fingering-diagram {
        top: 35px;
        left: 4px;
      }
    }
  }
  &.recorder {
    max-height: $recorder-fingering-height;
    & button.fingering {
      font-size: $recorder-fingering-height;
      height: $recorder-fingering-height;
      width: $recorder-fingering-width;
      & .fingering-diagram {
        top: 32px;
        left: 3px;
      }
    }
  }
}

.fingering-diagram {
  position: absolute;
  box-sizing: border-box;
  color: rgb(153, 79, 79);
  border: 1px solid transparent;
}

.fingering-base {
  color: #ababab;
}

.alternate-fingering-caret {
  // font-size: 0.8rem;
  // color: #bbb;
  color: rgb(109, 172, 172);
}


.eye-icon {
  position: relative;
  top: -4px;
}