
/*

 ######   ######     ###    ##       ######## 
##    ## ##    ##   ## ##   ##       ##       
##       ##        ##   ##  ##       ##       
 ######  ##       ##     ## ##       ######   
      ## ##       ######### ##       ##       
##    ## ##    ## ##     ## ##       ##       
 ######   ######  ##     ## ######## ######## 


##     ## ########    ###    ########  ######## ########  
##     ## ##         ## ##   ##     ## ##       ##     ## 
##     ## ##        ##   ##  ##     ## ##       ##     ## 
######### ######   ##     ## ##     ## ######   ########  
##     ## ##       ######### ##     ## ##       ##   ##   
##     ## ##       ##     ## ##     ## ##       ##    ##  
##     ## ######## ##     ## ########  ######## ##     ## 
*/


// box containing everything for a scale: header and notation
.scale-box { 
    margin: 0;
    padding-top: 0.4rem; 
    text-align: left;
  }
  
  .scale-header {
    border: 0;
    display: flex;
    max-width: 500px;
    justify-content: flex-start;
    padding: 0 $main-margin-width;
    // border: 1px dotted green;
    .scale-bullet {
      margin-right: 0.3em;
    }
  }
  
  // .scale-info {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  // }
  
  .scale-title {
    // font-family: $font-main, sans-serif;
    color: $color-primary-text;
    // margin-top: -0.2em;
    white-space: nowrap;
  
    // &.condensed {
    //   font-family: $font-condensed, $font-main, sans-serif;
    // }
  }
  
  .scale-message {
    font-size: 0.94em;
    color: $color-text-subdued;
  }
  