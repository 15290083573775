.dropdown-menu {
  // position: unset;   // 000504 this was breaking the sort menu position when open. Not sure what I intended with it..?
  box-shadow: -1px 1px 8px hsla(0,0,0,0.16);
}

.submenu-toggle::after {       // caret made from border
  margin-top: .5em;
  content: "";
  float: right;
  border-left: .3em solid #666;
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
}


.rml-item-highlight:not(:active) {    // match bootstrap hover
  color: #212121;
  background-color:#dddddd !important;
}

button.dd-item, .dd-item>div {   // make react-menu-list and bootstrap dropdowns match
  line-height: 1.7;
}