
.newgrid-container {
  margin: 0 $grid-margin 10px $grid-margin;
  padding-top: 0px;
  @include respond(phone-portrait) {
    margin-left: $phone-grid-margin;
    margin-right: $phone-grid-margin;
  }
}

.newgrid {
  display: flex;
  flex-wrap: wrap;
  &.column {
    flex-direction: column;
  }  
}


.keys-column {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  & .scale-bullet {
    margin: $grid-bullet-margin;
  }
}

.newgrid-group-heading {
  width: $grid-bullet-width;
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 1em;
  color: inherit;
  font-size: 0.9em;
  white-space: nowrap;

  @include respond(phone) {
    width: $phone-grid-bullet-width;
  }
}


.nav-sidebar-container:not(.grid-width-1) .newgrid-group-heading:not(.pitch) {
  width: $grid-bullet-width;
  margin-top: 3.5em;
  justify-content: flex-start;
  // font-size: 0.9em; 
  margin-bottom: -0.2em;
  padding: 0 0.6em;
  transform: rotate(315deg);
  
  // margin-bottom: 0.5em;
  // transform: rotate(270deg);

  @include respond(tab-land) {
    margin-top: 2.5em;
  }
}

// row labels are out atm
// .scale-bullet.in-keys-grid.row-label {
//   font-size: 0.9rem;
//   // justify-self: flex-end;
//   color: inherit;
//   border: none;
// }


$keys-grid-translation: 100vw;

.slide-in-from-left-enter {
  transform: translateX( -$keys-grid-translation );
}
.slide-in-from-left-enter-active {
  transform: translateX( 0px );
  transition: all 200ms
}
.slide-in-from-left-exit {
  transform: translateX(0);
}
.slide-in-from-left-exit-active {
  transform: translateX( -$keys-grid-translation );
  transition: all 160ms;
}