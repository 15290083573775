/*

##       ####  ######  ######## 
##        ##  ##    ##    ##    
##        ##  ##          ##    
##        ##   ######     ##    
##        ##        ##    ##    
##        ##  ##    ##    ##    
######## ####  ######     ##    

*/

// scale-list-container see layout.scss!


ul.scale-list {
  list-style-type: none;
  border: 0;
  padding: 0 0 1px 0;
  margin-bottom: 20px;
  background-color: #fff;

  li {
    margin-bottom: 30px;
  }
}

.list-header {
  padding: 10px $main-margin-width;
}

.instrument-title {
  color: #555;
  text-transform: uppercase;
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 0;
}

.bottom-spacer {
  padding-top: 70vh;
}

.list-header-dropdown {
  background-color: #fff;
  font-weight: 600;
  color: $color-action-item;
  &:not(.active):hover:enabled,
  &:not(.active):focus:enabled {
    background-color: $color-action-hover;
    color: $color-action-item;
  }
}

