// following values for a pale navbar

// also should probably set up a common class for nav buttons - i.e. common to clef, grade pills, ordering, group navs.


// $color-navbar: #c4efe7;
$color-navbar: #2fceb1;
// $color-pressed-button: scale-color(#59d2bc, $alpha: -70%, $lightness: -30%);
$color-pressed-button: scale-color($color-navbar, $saturation: -8%, $lightness: -13%);

$color-nav-border: scale-color($color-navbar, $lightness: 10%, $saturation: -40%);
$color-nav-text: #333; // #555;
$color-nav-text-on-button: rgb(97, 97, 97); 
$color-nav-text-subdued: rgb(117, 173, 169);

$color-nav-button: scale-color($color-navbar, $saturation: -40%, $lightness: 70%); //hsla(0,0%,99%,0.5);

$color-nav-hover: darken($color-nav-button, 85%);


$color-bullet-disabled: #000;

$color-nav-sidebar: #eaebec;
$color-phone-grid: scale-color(#3f4041, $alpha: -20%);

$color-sidebar: scale-color(#353432, $lightness: -18%);
$color-sidebar-active: scale-color($color-sidebar, $lightness: 8%);
$color-sidebar-lines: scale-color($color-sidebar-active, $lightness: 8%);
$color-sidebar-button: scale-color($color-sidebar, $lightness: 22%);
$color-tab-border: #525252;
$color-sidebar-text: #ccc;
$color-sidebar-text-subdued: scale-color($color-sidebar-text, $lightness: -33%);
$color-sidebar-link: scale-color($color-navbar, $lightness: -15%);