
.navbar-with-search {
  position: fixed;
  top: $navbar-height;
  height: $navbar-search-height;
  width: 100%;
  z-index: 11;

  &.phone {
    top: 0;
    height: $phone-navbar-search-height;
    // height: initial;
    align-items: flex-start;
    z-index: 1031 !important;   // suggestions box must be on top of bottom toolbar (set by bootstrap I think)

    @include respond(tab-land) {
      padding: 5px 10px;
    }
    @include respond(phone) {
      padding: 5px;
    }
    @include respond(phone-landscape-keyboard) {
      & .brand {
        display: none;
      }
    }
  }
}
 

    
form.search-bar {
  width: 50vw;
  // width: 100%;
  // min-width: 540px;
  // max-width: 600px;

  // @include respond(tab-land) {
  //   width: 90vw;
  //   // min-width: 100%;
  // }

  @include respond(tab-land) {    // ??? might want to make less on tablet, particularly in landscape..
    width: 100%;
    min-width: 100%;
  }
}

.rbt-menu {   // i.e. the suggestions box
  position: absolute; 
  display: block; 
  max-height: 300px; 
  overflow: auto; 
  will-change: transform; 
  width: 100%; 
  top: 0px; 
  left: 0px; 
  transform: translate3d(0px, 38px, 0px);
  border: 1px solid #ccc;
  @include respond(phone-landscape) {
    max-height: 13em;
    // border: 2px solid red !important;
  }
}


div.token-ignored {
  color: #999;
  background-color: #eee;
  text-decoration: line-through;
}


.rbt {                 // rbt = react-bootstrap-typeahead
  position: relative;
  flex-grow: 10;
}


.rbt-embed-left, .rbt-embed-right {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.2em;
  margin-right: 0.1em;
  height: 100%;
  width: 1.8em;
  font-size: 1.1em;
  top: 0;
  z-index: 5;
  
  & button {
    background: transparent;
    flex-grow: 2;
    margin: 0.2em 0;
    height: 1em;
    border: none;
    color: #777;
    transition: color 0.1s;
    padding: 0;

    &:hover, &:active {
      color: #000;
      outline: none;
    }
  }

  &.small {
    font-size: 1em;
    margin-left: 0;
    margin-right: 0.2em;
    width: 1.5em;

    & button {
      padding-left: 0.3em;
      padding-right: 0.1em;
    }
  }


}

.rbt-embed-left {
  left: 0.1em;
}

.rbt-embed-right {
  right: 0.1em;
  & button {
    border-left: 1px solid #ccc;
  }

}

.search-bar .form-control {
  padding-left: 2.5em;             // space for search icon/button
  padding-right: 2.5em + 1.8em;    // room for clear button
  border: 1px solid scale-color($color-navbar, $lightness: -15%);
}

.rbt-aux {            // rbt's clear button
  right: 2.3em;
  margin-top: 0.2em;
}

.form-control.is-invalid {
  background-position-x: 81%;
  // background-image: none;      // to get right of exclamation point
}


// .below-search-bar {
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-start;
// }

// .search-feedback {
//   font-size: 0.8em;
//   color: #777;
// }

.header-control {
  font-size: 0.8em;
}

.suggestion-desc {
  margin-left: 0.3em;
  color: rgb(155, 155, 155);
  // font-weight: 300;
  font-size: 0.88em;
  // font-style: italic;
}

.dropdown-item.active .suggestion-desc {
  color: rgb(136, 212, 209);
}


.phone-search-bar-enter {
  transform: translateY( -100% );
}
.phone-search-bar-enter-active {
  transform: translateY( 0px );
  transition: all 200ms;
}
.phone-search-bar-exit {
  transform: translateY( 0 );
}
.phone-search-bar-exit-active {
  transform: translateY( -100% );
  transition: all 200ms;
}
