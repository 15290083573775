
/*


 ######   ########   #######  ##     ## ########   ######  
##    ##  ##     ## ##     ## ##     ## ##     ## ##    ## 
##        ##     ## ##     ## ##     ## ##     ## ##       
##   #### ########  ##     ## ##     ## ########   ######  
##    ##  ##   ##   ##     ## ##     ## ##              ## 
##    ##  ##    ##  ##     ## ##     ## ##        ##    ## 
 ######   ##     ##  #######   #######  ##         ######  

*/


.group-header {
    padding: 10px $main-margin-width;
    margin-bottom: 10px;
    // background-color: $color-group-header;
    
    // .group-title {
      // @include font-header;
      // font-size: 1.5em;
      // text-transform: capitalize;
    // }
  
    @include respond(phone) {
      border-top: none;
      
      & ~ .group-header {
        border-top: 1px solid #ccc;  // all but first group-header
      }
    }
  }
  
  