/*

Styles for music-related stuff.

*/

@function first($list) { @return nth($list, 1);}
@function last($list) { @return nth($list, length($list));}

@mixin setBgColorAndContrastingText($bg-color) {
  background-color: $bg-color;
  $text-color: color-contrast($bg-color, scale-color($bg-color, $lightness: 95%), scale-color($bg-color, $lightness: -86%));
  color: $text-color;
  &:hover {
    color: $text-color;
  } 
}

// $key-colors: #00007f,#35268f,#53469f,#6c66ae,#8488bb,#9aaac5,#afcfca,#bdf8a5,#efd663,#f5bd47,#f7a332,#f78821,#f56b12,#f14805,#ec0000;
// $key-colors: #0000cf,#4433d5,#6155d9,#7875dc,#8b95dd,#9bb5da,#abd6d1,#bdf8a5,#fdce66,#fcb74e,#fb9e3a,#f98429,#f56819,#f1460b,#ec0000; // https://gka.github.io/palettes/#/15|d|0000cf,a7f3f2,bdf8a5|ffe2ae,f3fc60,ec0000|1|1
// $key-colors: #0000cf,#4433d5,#6155d9,#7875dc,#8b95dd,#9bb5da,#abd6d1,#bdf8a5,#efd663,#f5bd47,#f7a332,#f78821,#f56b12,#f14805,#ec0000;
// $key-colors: #00429d,#325da9,#4e78b5,#6694c1,#80b1cc,#9dced6,#c0eade,#ffffe0,#ffdac4,#ffb3a7,#fb8a8c,#eb6574,#d5405e,#b81b4a,#93003a;

$key-colors: #0041ca,#3460c6,#4c7ec2,#609abe,#78b6bb,#96d0b7,#c0e8b4,#fbfbb3,#ffdb83,#ffc075,#ffa567,#fb8959,#f76a4b,#f1473c,#e9002c;
// https://gka.github.io/palettes/#/15|d|0041ca,00d7b0,fbfbb3|fef591,f7634e,e9002c|1|1


$first-color-key-sig-index: -7;
$how-many-flat-colors: -$first-color-key-sig-index;
$flattest-color: first($key-colors);
$sharpest-color: last($key-colors);

$dark-text-default: #000 !default;
$light-text-default: #fff !default;



// Following from https://codepen.io/bluesaunders/details/FCLaz
//
// ==========================================================================
// Calculeate brightness of a given color.
@function brightness($color) {
  @return ((red($color) * .299) + (green($color) * .587) + (blue($color) * .114)) / 255 * 100%;
}

// Compares contrast of a given color to the light/dark arguments and returns whichever is most "contrasty"
@function color-contrast($color, $dark: $dark-text-default, $light: $light-text-default) {
  @if $color == null {
    @return null;
  }
  @else {
    $color-brightness: brightness($color);  
    $light-text-brightness: brightness($light);
    $dark-text-brightness: brightness($dark);
    
    @return if(abs($color-brightness - $light-text-brightness) > abs($color-brightness - $dark-text-brightness), $light, $dark);  
  }
}
// ==========================================================================


// Catch-all for 'off the chart' sharp keys..
[class*=" key_"] {
  @include setBgColorAndContrastingText(scale-color($sharpest-color, $lightness: -85%));
}

// ..and very flat ones:
[class*=" key_-"] {
  @include setBgColorAndContrastingText(scale-color($flattest-color, $lightness: -85%));
}

$index: $first-color-key-sig-index;
@each $color in $key-colors {
  .key_#{$index} {
    @include setBgColorAndContrastingText($color);
  }
  $index: $index + 1;
}

// do a few more flat ones and sharp ones
@for $i from 1 through 3 {
  $index: $i + $how-many-flat-colors;
  .key_#{$index} {@include setBgColorAndContrastingText(scale-color($sharpest-color, $lightness: -25% * $i ));}
  .key_-#{$index} {@include setBgColorAndContrastingText(scale-color($flattest-color, $lightness: -25% * $i ));}
}



.type-CHR:not(.disabled) {
  background-image: linear-gradient(135deg, hsla(0, 100%, 56%, 1) 10%, hsla(60, 100%, 66%, 1) 40%, hsla(120, 80%, 52%, 1) 65%, hsla(240, 100%, 70%, 1) 80%), linear-gradient(#fff, #fff);
  color: #000;
}

.WT-0 {
  @include setBgColorAndContrastingText(rgb(46, 45, 45));
}

.WT-1 {
  @include setBgColorAndContrastingText(rgb(219, 217, 217));
}

$diminished-colors: #634A74, #608F58, #AD966B;

@for $i from 0 through 2 {
  .diminished-#{$i} {
    @include setBgColorAndContrastingText(nth($diminished-colors, $i + 1));
    border-radius: 0.3em;
  }
}

.music-symbol {
  display: inline-block;
  font-family: bravura-text;
  font-size: 0.8em;
  line-height: 0;
  
  &.DIM7 {
    font-size: 0.5em;
    vertical-align: 0.6em;
  }
}

.accidental {
  // font-family: 'Arial Unicode MS', 'Lucida Sans Unicode';
  margin-left: 0.05em;
  margin-right: 0.05em;
}

.treble-clef {
  font-size: 0.95em;
  vertical-align: 0.2em;
}

.bass-clef {
  font-size: 1.0em;
  vertical-align: 0.48em;
}  
  
.flat-sign {
  vertical-align: -0.02em;
}  

.sharp-sign {
  vertical-align: 0.07em;
}

.natural-sign {
  font-size: 0.87em;
  vertical-align: 0.02em;
}

.double-sharp {
  font-size: 1.6em;
  vertical-align: 0.17em;
}

.double-flat {
  font-size: 1.4em;
  vertical-align: 0.125em;
}
