/*

88888888ba   88888888ba   88  888b      88  888888888888  
88      "8b  88      "8b  88  8888b     88       88       
88      ,8P  88      ,8P  88  88 `8b    88       88       
88aaaaaa8P'  88aaaaaa8P'  88  88  `8b   88       88       
88""""""'    88""""88'    88  88   `8b  88       88       
88           88    `8b    88  88    `8b 88       88       
88           88     `8b   88  88     `8888       88       
88           88      `8b  88  88      `888       88       
                                                          

*/

.brand-print {
  position: absolute;
  right: 0;
  text-align: right;
  transform: scale(1.4,1.4);
  transform-origin: top right;
}

@page { size: auto; margin: 13mm 8mm 20mm 8mm; }
@media print {
  .print-hide,
  .print-hide * {
    display: none !important;
  }
  // .print-footer {
  //   position: fixed;
  //   bottom: 0;
  // }

  #main-window {
    position: initial;
    top: 0;
    margin-top: 0;
  }

  #scale-list-container {
    position: initial;
    width: 100% !important;  // to override .sidebar-open class
    height: initial;
    margin-top: 0;
    overflow: visible;
  }
  
  #settings-window { 
    display: none;
  }

  .scale-list-container {
    margin-top: 0;
  }

  .list-header {
    padding-left: 0;
    margin-top: 0;
  }    
  
  .group-header {
    padding-left: 0; 
    break-inside: avoid-page;
    // break-after: avoid-page;   // ??? doesn't work - 000144

    &::after {          // ??? doesn't work? cf 000144, and https://stackoverflow.com/a/53742871
      content: "";
      display: block;
      height: 400px;
      margin-bottom: -400px;
    }
  }

  .scale-list {
    margin-top: 0; /* Reduced, cos don't need to make way for navbar */
    // margin-bottom: 0;
    // border: 1px solid #666;

    &>li {
      // margin-top: 0;
      margin-bottom: 10px !important;
      // border: 1px solid #666;
    }
  }

  .scale-box {
    // margin-top: 30px;
    break-inside: avoid-page;
  }
  .scale-header {
    padding-left: 0;
    // position: absolute;
  }
  .scale-bullet {
    color: #666;
    border: 1px solid #888;
    box-shadow: none;
  }
  .scale-title {
    text-decoration: none !important; 
    opacity: 1;   /* Gets pixelated on print from firefox if opacity is < 1 [63] */
    color: #000;
    font-size: 120%;  // ??? change to em
  }
  .notation-cssgrid {
    display: block;
    overflow-x: hidden;  /* Turn off horizontal scrolling to remove the scrollbars between each scale */
  }
  .dropdown-toggle {
    color: $color-action-item;   // for inst variety on list header
    background-color: #fff;
    &::after {
      display: none;             // hide the dropdown caret
    }
  }
  // .fingering-container {
  //   font-size: 0.9em;
  // }  
}

@media screen {
  .print-show {      // Items which are to be shown ONLY on print
    display: none;
  }
}
