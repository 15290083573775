/*


##          ###    ##    ##  #######  ##     ## ######## 
##         ## ##    ##  ##  ##     ## ##     ##    ##    
##        ##   ##    ####   ##     ## ##     ##    ##    
##       ##     ##    ##    ##     ## ##     ##    ##    
##       #########    ##    ##     ## ##     ##    ##    
##       ##     ##    ##    ##     ## ##     ##    ##    
######## ##     ##    ##     #######   #######     ##    

I.e. how big elements fit together, but not the details of those elts
- main window
- sidebar
- navbar..?

*/


// The top (blue) area: navbar, search bar etc.
// #header-container {
// }



// Everything below the header area.
// Contains navsidebar and scaleslist
// It's a fixed window and things scroll within it.
#main-window {
  position: fixed;
  // display: flex;
  height: calc(100vh - #{$header-height});
  width: 100%;
  top: $header-height;
}

.nav-sidebar-container { 
  position: fixed;
  box-sizing: border-box;
  margin: 0;
  padding-bottom: $grid-bullet-margin;
  height: calc( 100vh - #{$header-height} );
  color: #666;
  background-color: $color-nav-sidebar;
  border-right: 1px solid #ccc;
  // overflow-y: auto;   // else tooltips all go left and may be largely off-screen..
  overflow-y: auto;      // REINSTATED cos now using rc-tooltip, not react-floater and it works (000440)
  z-index: 1029;
  // z-index: 1;    // else overflowing group labels are chopped
}



// ??? all these rules *were* on scale-list-container but I thought to have the scrollable thing in <Main> so
// that I could handle the sidebar-open stuff there; generally keep the layout up there.
// But the groups navbar is defined in <ScaleList>, so that's confusing the scrollbar a little.
// I *could* just revert, but probably correct solution is to set up the groups navbar from <Main> - but 
// *outside* the scrollable thing.

#scale-list-container { 
  position: relative;
  // height: calc(100% - #{$header-height});
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  // flex-grow: 1;
  // flex-shrink: 1;
  background-color: #fff;   // else feature tour looks messy (group-by button)
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 1000;
  // z-index: 1032;
  background-color: rgba(0,0,0,0.6);

  &.phone-grid-open {
    @include respond(tab-land) {
      display: none !important;
    }
    @include respond(phone) {
      display: block !important;
    }
  }

  &.for-search {
    z-index: 1030;
  }

  &.sidebar-open {
    z-index: 1032;  // enough to cover search bar on phone, but not bottom menu
  }
}




#settings-window { 
  position: fixed;
  top: 0;         // $navbar-top + $navbar-height;
  height: 100%;   // calc(100% - #{$navbar-top} - #{$navbar-height});
  min-height: 100vh;
  right: 0;
  width: $settings-sidebar-width;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  z-index: 1032;            // enough to cover .navbar-with-search.phone, but less than phone menu (bottom)
  box-shadow: -3px 0 4px -2px rgba(0,0,0,0.5);

  @include respond(phone) {
    width: 100vw;
    }


  .settings { 
    min-height: 100%;
    width: 100%;
  }
}


/*

########    ###    ########  ##       ######## ########          ##    ########  ##     ##  #######  ##    ## ######## 
   ##      ## ##   ##     ## ##       ##          ##            ##     ##     ## ##     ## ##     ## ###   ## ##       
   ##     ##   ##  ##     ## ##       ##          ##           ##      ##     ## ##     ## ##     ## ####  ## ##       
   ##    ##     ## ########  ##       ######      ##          ##       ########  ######### ##     ## ## ## ## ######   
   ##    ######### ##     ## ##       ##          ##         ##        ##        ##     ## ##     ## ##  #### ##       
   ##    ##     ## ##     ## ##       ##          ##        ##         ##        ##     ## ##     ## ##   ### ##       
   ##    ##     ## ########  ######## ########    ##       ##          ##        ##     ##  #######  ##    ## ######## 

*/

// Some of the adjustments are to make sure that the browser address bar will scroll away on a
// tablet or phone (at least Chrome does..)


$bullet-space: $grid-bullet-width + 2 * $grid-bullet-margin;
$phone-bullet-space: calc(#{$phone-grid-bullet-width} + 2 * #{$phone-grid-bullet-margin});

@mixin grid-width ($i) {
  $nav-sidebar-width: calc(#{$grid-margin} * 2 + #{$bullet-space} * #{$i} + 1px);
  &.nav-sidebar-container {
    width: $nav-sidebar-width;
  }
  &#scale-list-container {
    left: $nav-sidebar-width;
    width: calc(100% - #{$nav-sidebar-width});
  }

  @include respond(phone) {
    $phone-grid-width: calc(#{$phone-grid-margin} * 2 + #{$phone-bullet-space} * #{$i} + 1px);
    &.nav-sidebar-container {
      width: $phone-grid-width;
    }
  }
}

@for $i from 1 through 12 {
  .grid-width-#{$i} {
    @include grid-width($i)
  }
}

.grid-width-0 {
  &.nav-sidebar-container {
    display: none !important;
  }
  &#scale-list-container {
    left: 0;
    width: 100%;
  }
}


@include respond(tab-land) {
  #main-window {
    position: static;    // nb. <Link>s in ScaleBullet examing main-window's position css prop to work right! cf 000434
    display: block;
    height: 100%;
  }

  .nav-sidebar-container { 
    position: fixed;
    top: 0;
    height: 100%;
    min-height: 100%;
    padding-bottom: calc(#{$phone-navbar-height} + 10px);
    overflow-y: auto;     // might as well cos there are no tooltips on tablet/phone
                          // (It's switched off on main class as it pushes tooltips off screen.)
  }

  // #settings-window {
    // height: calc( 100vh - #{$phone-navbar-height} );
    // min-height: calc( 100vh - #{$phone-navbar-height} );
  // }

  #scale-list-container { 
    position: relative;
    top: 0;
  }

  .grid-width-12 {
    @include grid-width(6);
  }
}


@include respond(phone) {

  // #main-window {
  //   top: 0;
  //   height: 100%;    // Need this else Chrome address bar does not scroll away 000434
  // }

  .nav-sidebar-container {
    position: fixed;
    top: 0;
    background-color: $color-phone-grid;
    backdrop-filter: blur(4px);
    border-right: 1px solid scale-color($color-phone-grid, $lightness: -10%);
    color: #ccc;
  }

  #scale-list-container { 
    // position: relative;
    // top: 0;
    left: 0 !important;          // 'important' so as to ignore grid-width changes
    width: 100% !important;
  }
}
