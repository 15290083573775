
/*

 ######                                           
 #     # #    # #      #      ###### #####  ####  
 #     # #    # #      #      #        #   #      
 ######  #    # #      #      #####    #    ####  
 #     # #    # #      #      #        #        # 
 #     # #    # #      #      #        #   #    # 
 ######   ####  ###### ###### ######   #    ####  
                                                  
 */


// both for group header bullets and for each scale; specific refinements further below...
.scale-bullet {       
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  line-height: 1;
  color: #fff;
  white-space: nowrap;
  border: 1px solid hsla(230, 0%, 0%, 0.1);
  // border: none;
  // border-radius: 0.16em;
  border-radius: 0;
  // background-image: linear-gradient(135deg, hsla(255,0,0,0.3) 10%, hsla(255,255,0,0) 10%);  // little triangle in top-left corner to indicate start note

  &.full-size {
    width: 46px;
    min-width: 46px;
    height: 46px;  
    font-size: 1.7em;
    font-weight: 500;  
    // border: 1px solid white;
    // box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.21);

    @media print {
      border: 1px solid #666;
      box-shadow: none;
    }
  }

  &:hover {
    cursor: default;
  }
}

.bullet-letter {
  font-family: Asap, Arial, sans-serif;
}

.bullet-suffix {
  font-family: 'Asap condensed', 'Arial Narrow', sans-serif;
  font-weight: 400;
  font-size: 0.7em;
  // padding-left: 0.1em;
  vertical-align: 0.5em;
  line-height: 0;

  &.diminished {
    font-family: 'Asap';
    font-size: 1.4em;
    font-weight: 400;
    vertical-align: -0.2em;
  }
 
  &.augmented {
    font-size: 1.0em; 
    font-weight: 400;
    vertical-align: 0.35em;
  }
  
  &.half-diminished {
    font-size: 0.47em; 
    font-weight: 600;
    vertical-align: 0.82em;
    margin-left: 0.2em; 
  }

}




.scale-bullet.in-keys-grid {
  // max-width: 1.4em;
  // max-height: 1.4em;
  font-size: 1.1em;
  font-weight: 500;
  width: $grid-bullet-width;
  height: $grid-bullet-width;
  // margin-right: $bullet-in-keys-grid-spacer;
  transition: all 0.1s;

  &.active:hover {
    cursor: pointer;
    opacity: 1;
    transform: scale(1.2);
    // border: 1px solid white;
  }

  &:active {
    @include pressed();
    @include dark-overlay();
  }

  &.disabled {
    // color: hsla(0,0,30%,1);                // LIGHT
    // border: 1px solid hsla(0,0,70%,1);      // LIGHT
    color: $color-bullet-disabled;
    border: none;
    background-color: transparent;
    opacity: 0.1;

    @include respond(phone) {
      opacity: 0.3;
    }

    &:hover {
      cursor: default;
    }
  }

  // ??? removed following - wanted bullets larger on tablet, but was getting too fiddly.
  // ??? complicated by group nav buttons changing to plain divs on phone, which is controlled
  // by bootstrap d-...-sm -type classes

  // @include respond(tab-port) {
  //   font-size: 2.5vw;
  //   width: 4.8vw;
  //   height: 4.8vw;
  //   max-width: 1.7em;
  //   max-height: 1.7em;
  //   margin: auto 0.2em;
    
  // }

  @include respond(phone) {
    width: $phone-grid-bullet-width;
    height: $phone-grid-bullet-width;
    font-size: (56vmin/12);
    margin: $phone-grid-bullet-margin $phone-grid-bullet-margin;
  }
}

// .scale-bullet.small {
//   // transform: scale(0.9);
//   opacity: 0.5;
// }


.type-CHR { 
  border-radius: 20em; 
  border: none;
}



