.landing-page-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100vh - #{$header-height});
    width: 100%;
    overflow-y: auto;
    padding-top: 7vh;
    font-size: 1.5rem;
    font-weight: 300;
    background-color: #eeeeee;
    color: #8f8f8f;

    @include respond(tab-land) {
        height: 100%;               // % cos 100vh screws things up slightly on ipad..
        min-height: 100vh;          // vh cos 100% doesn't go to bottom
        padding-top: calc(#{$phone-navbar-search-height} + 2em);
    }
    @include respond(phone) {
        padding-top: calc(#{$phone-navbar-search-height} + 1em);
        font-size: 1.35rem;
    }
}

.landing-page-block {
    display: flex;
    width: 60%;
    max-width: 690px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: $phone-navbar-height;
    &>p {
        margin: 0;
    }
    &>button {
        padding: 0.3em 0.3em 0.3em 0;
        line-height: 1;
        font-weight: 300;
        color: inherit;
        background: none;
        border: none;
        flex-shrink: 0;   // else it gets shrunk on ipad for some reason
        &>.react-icon-adjust {
            color: #aaa;
        }
    }

    @include respond(phone) {
        width: calc(100% - 2em);
    }
}

.type-it {
    display: inline-block;
    font-weight: 600;
    color: #606060;
}

.tip-note {
    font-size: 0.8em;
}

.landing-page-brand-icon {
    margin-top: 3em;
    opacity: 0.7;
    @include respond(phone-landscape) {
        display: none;
    }
    @include respond(phone) {
        margin-top: 1.6em;
    }
}

.emoji {
    font-size: 2em;
}


.info-card {
    position: relative;
    // padding: 0.6em;
    width: 100%;
    margin: 1em 0;
    font-size: 1.1rem;

    &>.card-heading {      // header text
        margin-left: 4.3rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // background-color: lightsalmon;
    }

    &>h2 {    
        margin-left: 4.3rem;
    }
        
    & h2 {
        display: inline-block;
        white-space: nowrap;
        font-size: 1.3em;
        font-weight: 300;
        margin-right: 0.7em;
        margin-bottom: 0;
        line-height: 1.5em;
    }

    & a {
        color: $color-link;
        // font-weight: 400;
    }

    @include respond(phone) {
        margin: 0.5em 0;
    }
}

$badge-color: scale-color($color-navbar, $saturation: -30%, $lightness: 35%);

.teacher-badge {
    display: inline-block;
    white-space: nowrap;
    font-size: 0.9em;
    font-weight: initial;
    line-height: 1.5em;
    padding: 0.13em 0.5em;
    color: #fff;
    background-color: $badge-color;
    border-radius: 0.4em;

    &>svg {
        line-height: 1em;
        font-size: 1.6em;
        vertical-align: -30%;
    }
}

.icon-box {
    position: absolute;
    top: 0em;
    left: 0em;
    margin-top: 0.4em;
}

.icon-huge {
    font-size: 3.1rem;
    color: #a7a8a8;
}

.content {
    // margin-top: 0.5em;
    margin-left: 4.3rem;
    &>*:not(iframe) {       // exclude the iframe for useWidthDetector
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }
}

.examples {
    &>ul {
        margin: 0;
        padding-left: 1.2em;
        &>li {
            margin-top: 0.2em;
            color: #aaa;
        }
    }
}

.notation-example {
    font-size: 1rem;
    margin-left: -10px;
}


.print-example-thumbnail {
    box-shadow: -1px 1px 8px hsla(0,0,0,0.16);
}

.image-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 5999;
    background-color: hsla(0,0,0,0.7);
}

.image-full-screen {
    position: absolute;
    width: 94%;
    top: 4%;
    left: 3%;
    padding-bottom: 4%;
    &>img {
        width: 100%;
        box-shadow: 0px 0px 25px 3px hsla(0,0,0,0.76);
    }
}

#what-else {
    margin-top: -$phone-navbar-search-height;
    padding-top: $phone-navbar-search-height;
}