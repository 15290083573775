
/*

888b      88         db   8b           d8  88888888ba         db         88888888ba   
8888b     88        d88b  `8b         d8'  88      "8b       d88b        88      "8b  
88 `8b    88       d8'`8b  `8b       d8'   88      ,8P      d8'`8b       88      ,8P  
88  `8b   88      d8'  `8b  `8b     d8'    88aaaaaa8P'     d8'  `8b      88aaaaaa8P'  
88   `8b  88     d8YaaaaY8b  `8b   d8'     88""""""8b,    d8YaaaaY8b     88""""88'    
88    `8b 88    d8""""""""8b  `8b d8'      88      `8b   d8""""""""8b    88    `8b    
88     `8888   d8'        `8b  `888'       88      a8P  d8'        `8b   88     `8b   
88      `888  d8'          `8b  `8'        88888888P"  d8'          `8b  88      `8b  

*/

// Make Buttons on the navbar (i.e. Sort buttons) have focus ring like other elements. 000165
//
// 'outline' lines copied from following from bootstrap's _reboot.scss:
//
//    Work around a Firefox/IE bug where the transparent `button` background
//    results in a loss of the default `button` focus styles.
//
//    Credit: https://github.com/suitcss/base/
//
// button:focus {
//   outline: 1px dotted;
//   outline: 5px auto -webkit-focus-ring-color;
// }

.btn:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
  box-shadow: none;
}
  

.navbar-backdrop {
  position: fixed; 
  left: 0;
  width: 100%;
  background: $color-navbar; /* Old browsers */
  // background: linear-gradient(to bottom, $color-navbar 0%, scale-color($color-navbar, $lightness: 60%) 100%); 
  z-index: 1;

  &.top {
    top: 0;
    border-bottom: 1px solid $color-nav-border;
  }

  &.main {
    height: $header-height;
  }

  &.navbar-phone {
    border-top: 1px solid $color-nav-border;
  }
}

.navbar {
  padding: 0 $main-margin-width;
  z-index: 1039 !important;

  & .nav-link {
    color: $color-nav-text;
    transition: color 0.2s;
    &:hover {
      color: $color-nav-hover;
    }
  }
}

.navbar-main {
  top: 0;
  height: $navbar-height; 
  border-bottom:  $color-nav-border;
  justify-content: space-between;
}

.navbar-sorting {
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  top: $navbar-height + $navbar-search-height;
  height: $navbar-sorting-height;
  // font-size: 0.8em;
  padding: 0px $main-margin-width;
  z-index: 1;
  // background-color: yellow;
 
  & button.nav-link {
    margin-right: 5px;
    padding: 3px 6px;
    font-size: 1rem;
    color: $color-nav-text;
    &:hover {
      background-color: transparent;
    }
    &:disabled {
      background-color: transparent;
      color: $color-nav-text;
      opacity: 0.5;
    }
  }
}

.sorting-menu {         // ??? only used for the separate dropdowns (obsolete?)
  margin-top: 3px;
  padding: 0 4px;
  // margin-left: 50px;
  font-size: 0.9em;
  color: $color-action-item;
  background-color: transparent;
  border: none;
  border-radius: 80px;
  box-shadow: none; 
  color: $color-nav-text-on-button;     // ??? obsolete?
  &:hover,&:active,&:focus {
    color: darken($color-action-item, 20%);
    background-color: transparent;
  }
}



/*

########  ##     ##  #######  ##    ## ######## 
##     ## ##     ## ##     ## ###   ## ##       
##     ## ##     ## ##     ## ####  ## ##       
########  ######### ##     ## ## ## ## ######   
##        ##     ## ##     ## ##  #### ##       
##        ##     ## ##     ## ##   ### ##       
##        ##     ##  #######  ##    ## ######## 

*/

.navbar-phone {
  bottom: 0;
  height: $phone-navbar-height; 
  border-top: $color-nav-border;
  justify-content: space-between;
  font-size: 1.4rem;
  
  @include respond(phone-onscreen-keyboard) {
    display: none;   // hide bottom navbar on phone when keyboard is on screen
  }

  & button.nav-link {
    padding: 5px !important;
    min-width: 2.5em;
    background-color: transparent;

    &:disabled {
      opacity: 0.5;
    }
  }

  & .btn {
    font-size: inherit;   // override bootstrap for sort dropdown on phone navbar
    border-radius: 0;
  }
}

.nav-phone {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

button.nav-link {
  background: transparent;
  border: none;
  &:focus:not(.pressed) {
    box-shadow: none !important;
    background-color: transparent;
  }
  &:active:not(:disabled),&.pressed:not(:disabled) {
    background-color: $color-pressed-button !important;   // Had to override a few things for 'sort' btn (a bootstrap dropdown)
    border: none !important;
    color: inherit !important;
    @include pressed;
  }
  &.close-button {
    font-size: 1.4rem;    
  }
}


.nav-item {
  &:first-child {
    margin-left: 0;
  }
}

.navbar-text {
  color: $color-nav-text;
  font-weight: 500;
}

a:hover {
  text-decoration: none;
}

button.dropdown-item {
  color: $color-primary-text;
  font-size: 1em;

  &:hover {
    color: $color-primary-text;
    background-color: $color-dropdown-item-hover;
  }
}

$nav-button-radius: 5px;

// .nav-link.grade-pill {
//   min-width: 28px;
//   color: $color-nav-text-on-button;
//   background-color: $color-nav-button; 
//   transition: background-color 0.2s;
//   padding: 1px 6px;
//   text-align: center;
//   border: none;
//   // border: 1px solid $color-nav-item-border;
//   border-radius: 0px;

//   &:hover {
//     // color: $color-nav-hover;
//     background-color: $color-nav-hover;
//     // color: lighten($color-nav-text,40%);
//   }

//   &.all-scales {
//     border-left: 2px solid $color-nav-item-border;
//   }

//   &.active {
//     background-color: $color-nav-active;
//     color: $color-primary-text;
//     // color: darken($primary-color-dark, 15%);
//     opacity: 1;
//     @include pressed();
//   }
    
//   &.disabled {
//     color: $color-nav-disabled;
//     background-color: transparentize($color-nav-button, 0.5);
//     // opacity: 0.5;
//   }
// }

// .nav-item:first-child>.nav-link.grade-pill {
//   border-top-left-radius: $nav-button-radius;
//   border-bottom-left-radius: $nav-button-radius;
// }

// .nav-item:last-child>.nav-link.grade-pill {
//   border-top-right-radius: $nav-button-radius;
//   border-bottom-right-radius: $nav-button-radius;
// }

.app-version {
  color: scale-color($color-navbar, $saturation: -25%, $lightness: -25%);
  font-size: 0.7rem;
}  


.phone-navbar-button-text {
  font-size: 0.6em;
  line-height: 1em;
  // padding-top: 0;
}